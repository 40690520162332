<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="bank_offers" :service="currentServiceStr" backlink="/profile/notifications" />
      <div class="content">
        <div class="content__container">
          <div class="bank__sort">
            <v-select
              v-model="sort"
              item-text="name"
              item-value="value"
              class="select__dropdown"
              :items="getList('banks_select_1_value_', 4)"
              :hint="'sort' | localize"
              append-icon="icon-arrow-down"
              persistent-hint
            ></v-select>
          </div>
          <div class="bank__container">
            <div class="bank" v-for="(bank, k) in bankSorted" :key="k">
              <p class="bank__caption" :class="{bank__caption_best: isBestBank(bank.id)}">
                <span class="icon-bank"></span>
                {{ bank.name }}
              </p>
              <div class="bank__item" :class="{bank__item_best: isBestBank(bank.id)}">
                <div v-if="bank.programs">
                    <div v-for="(program, k) in bank.programs" :key="k">
                    <div class="bank__item_title">{{ program.name }}</div>
                    <div class="bank__row">
                        <div class="bank__row_caption">
                        {{ "bank_sum" | localize }}
                        </div>
                        <div class="bank__row_text">
                        {{ program.amount.toLocaleString() }}&nbsp;<span class="icon-₪"></span>
                        </div>
                    </div>
                    <div class="bank__row">
                        <div class="bank__row_caption">
                        {{ "bank_percent" | localize }}
                        </div>
                        <div class="bank__row_text">
                        {{ program.percent.toLocaleString() }} %
                        </div>
                    </div>
                    <div class="bank__row">
                        <div class="bank__row_caption">
                        {{ "bank_month" | localize }}
                        </div>
                        <div class="bank__row_text">{{ program.months }}</div>
                    </div>
                    <div class="bank__line" v-if="k+1 < bank.programs.length"></div>
                    </div>
                </div>
                <div class="bank__bottom">
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_payment" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank && bank.payment ? bank.payment.toLocaleString() : '-' }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_mortgage_sum" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank && bank.return ? bank.return.toLocaleString() : '-' }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <router-link
                    :to="{ name: 'Meet', params: { bank_id: bank.id, service_id: currentService } }"
                    class="button button__form"
                    >{{ "meet_button" | localize }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>

          <div class="error__container">
            <p class="error" v-if="error">{{ error | localize }}</p>
            <p v-else class="info info_3">
              {{ "bank_text_footer" | localize }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Common_bank_offers',
  components: { Backlink },
  props: { service_id: { type: Number } },
  mixins: [commonMixin],
  data: () => ({
    sort: null,
    currentServiceStr: null,
    error: null,
    banks: []
  }),
  created () {
    if (this.service_id) {
      this.currentService = this.service_id
    } else {
      this.loadServices()
    }
  },
  watch: { 
    currentService () {
      this.currentServiceStr = this.getServiceStr(this.currentService)
      this.loadOffers()
    }
  },
  methods: {
    loadOffers () {
      const userId = this.$store.state.user.id || 0
      this.$store.commit('loading', true)

      this.axios.get('user_service/bank_offers', {
        params: {
          user_id: userId,
          service_id: this.currentService
        }
      }).then((response) => {
        this.banks = response.data
        this.$store.commit('loading', false)
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    },
  }
}
</script>
